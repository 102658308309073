<!-- 政策管理 -->
<template>
  <div class="policy_management">
    <div class="container">
      <el-card style="margin-bottom: 10px">
        <el-form label-width="120px">
          <el-form-item label="申报地区：">
            <city-select-id
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
            />
          </el-form-item>
          <el-form-item label="发文机构：">
            <div class="radio_group">
              <div class="wrap_left">
                <el-radio-group
                  v-model="queryInfo.releaseUnit"
                  v-if="!isShowAllReleaseUnit"
                  @change="changeSearchangeSearch"
                >
                  <el-radio-button label="">全部</el-radio-button>
                  <div v-for="(item, index) in institution" :key="index">
                    <el-radio-button v-if="index < 5" :label="item"></el-radio-button>
                  </div>
                </el-radio-group>
                <el-radio-group v-model="queryInfo.releaseUnit" v-else @change="changeSearchangeSearch">
                  <el-radio-button label="">全部</el-radio-button>
                  <div v-for="(item, index) in institution" :key="index">
                    <el-radio-button :label="item"></el-radio-button>
                  </div>
                </el-radio-group>
              </div>
              <div class="showMore" @click="isShowAllReleaseUnitBtn">
                <div class="item" v-if="!isShowAllReleaseUnit">
                  <span>查看全部</span>
                  <i class="el-icon-arrow-up"></i>
                </div>
                <div class="item" v-else>
                  <span>收起</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="行业类别："></el-form-item> -->
          <el-form-item label="政策状态：">
            <div class="radio_group">
              <el-radio-group v-model="queryInfo.isDeclaration" @change="changeSearchangeSearch">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="1">已失效</el-radio-button>
                <el-radio-button label="2">未失效</el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="申报时间：">
            <el-date-picker
              v-model="dateList"
              @change="dateChanged"
              type="daterange"
              range-separator="至"
              start-placeholder="选择日期"
              end-placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card>
        <div class="content_top">
          <!-- 左边按钮 -->
          <div class="btns">
            <el-button type="primary" @click="addPolicy">新增</el-button>
            <el-button plain @click="deletePolicy">批量删除</el-button>
          </div>
          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入政策标题"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
              style="width: 500px"
            >
              <el-button slot="append" @click="changeSearchangeSearch" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" style="width: 100%" @selection-change="selectionChanged">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="编号" min-width="80" align="center">
              <template v-slot="{ row }">
                <span>G00{{ row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="declarationPolicyName"
              label="政策标题"
              min-width="200"
              align="center"
            >
              <template v-slot="{ row }">
              <span :class="!row.isFreeze ? 'color1' : 'color3'">{{
                  row.declarationPolicyName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="申报时间" min-width="100" align="center">
              <template v-slot="{ row }">
                <div>
                <span :class="!row.isFreeze ? 'color1' : 'color3'">{{
                    row.declarationStartTime
                  }}</span>
                  <span :class="!row.isFreeze ? 'color1' : 'color3'">至</span>
                </div>
                <span :class="!row.isFreeze ? 'color1' : 'color3'">{{ row.declarationEndTime }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="industryCategory"
              label="行业类别"
              min-width="120"
              align="center"
            ></el-table-column>
            <el-table-column label="申报地区" min-width="140" align="center">
              <template v-slot="{ row }">
                <span>{{ row.declarationCountry }}</span>
                <!-- <span>-{{ row.declarationProvince }}</span> -->
                <span v-if="row.declarationCity != null && row.declarationCity != ''">-{{ row.declarationCity }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="releaseUnit"
              label="发文机构"
              min-width="180"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="userName"
              label="操作人"
              min-width="80"
              align="center"
            ></el-table-column>
            <el-table-column prop="createTime" label="操作时间" min-width="140" align="center">
              <template v-slot="{ row }">
                <div>{{ row.createTime | date }}</div>
                <div>{{ row.createTime | time }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="操作" min-width="200" align="center">
              <template v-slot="{ row }">
                <div class="btn_list">
                  <span class="color1" @click="editPolicy(row.id)">编辑</span>
                  <span class="color1" @click="downloadPolicy(row)">下载文件</span>
                  <div @click="setFreeze(row)">
                    <span class="color2" v-if="row.isFreeze">激活</span>
                    <span class="color2" v-else>冻结</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>

    <div class="pagination">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import CitySelectId from '@/components/CitySelectId.vue'
import { policyList, selectInstitution, setFreeze, deletePolicy } from '@/api/policy.js'
import { apiDownloadFile } from '@/api/common.js'

const defaultQueryInfo = Object.freeze({
  country: '中国', //国家
  city: '', //市
  declarationEndTime: '', //申报结束时间
  declarationStartTime: '', //申报开始时间
  industryCategory: [], //行业类别集合
  isDeclaration: '', //申报政策状态，1是已失效，2是未失效
  orderByParam: '', //排序字段+ DESC或ASC
  pageNum: 1, //
  pageSize: 10, //
  province: '', //省
  queryConditions: '', //查询条件
  releaseUnit: '' //发文机构
})
export default {
  name: 'policyManagement',
  components: {
    CitySelectId
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo }, //查询发送的参数
      total: 0, //总条数
      tableData: [], //表格数据
      //政策状态
      policyStatus: [
        {
          name: '全部',
          label: 0
        },
        {
          name: '已失效',
          label: 1
        },
        {
          name: '未失效',
          label: 2
        }
      ],
      // 机构列表
      institution: [],
      dateList: [],
      isShowAllReleaseUnit: false, //展示全部发文机构
      rows: [], //table表格选中的行
      echo: 0 // 0是新增，1是编辑
    }
  },
  created() {
    this.searchCity('', 0)
    // this.search()
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    async search() {
      const { data: res } = await policyList(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取政策列表失败！')
      }
    },
    //批量删除政策
    deletePolicy() {
      let ids = []
      this.rows.forEach((item) => {
        ids.push(item.id)
      })
      this.$confirm('请确认是否删除 ?', '提示')
        .then(async () => {
          const { data: res } = await deletePolicy(ids)
          if (res.resultCode === 200) {
            this.$message.success('删除成功!')
            this.search()
          } else {
            this.$message.error('删除失败!')
          }
        })
        .catch(() => {})
    },
    //选中行
    selectionChanged(rows) {
      this.rows = rows
    },
    //
    isShowAllReleaseUnitBtn() {
      this.isShowAllReleaseUnit = !this.isShowAllReleaseUnit
    },
    //选中日期
    dateChanged(val) {
      if (val != null) {
        this.queryInfo.pageNum = 1
        this.queryInfo.declarationStartTime = val[0]
        this.queryInfo.declarationEndTime = val[1]
      } else {
        this.queryInfo.pageNum = 1
        this.queryInfo.declarationStartTime = null
        this.queryInfo.declarationEndTime = null
      }
      this.search()
    },
    //根据id查发文机构
    async selectInstitution(id) {
      const { data: res } = await selectInstitution({ id })
      if (res.resultCode === 200) {
        this.institution = res.data
      }
    },
    //返回省
    searchProvince(val, id) {
      if (id == 0) {
        this.selectInstitution(0)
      } else if (id != null) {
        this.selectInstitution(id)
      }
      this.queryInfo.pageNum = 1
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    async searchCity(val, id) {
      if (id == 0) {
        this.selectInstitution(0)
      } else if (id != null) {
        this.selectInstitution(id)
      }
      this.queryInfo.city = val
      this.search()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      localStorage.setItem('paginationCurrentPage', newCurrent)
      this.search()
    },
    //新增政策
    addPolicy() {
      this.$router.push({
        name: 'addPolicy',
        query: {
          echo: 0
        }
      })
    },
    //编辑政策
    editPolicy(id) {
      this.$router.push({
        name: 'addPolicy',
        query: {
          id,
          echo: 1,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    // 下载附件
    async imageDownload(data) {
      const res = await apiDownloadFile({ urlStr: data })
      if (res.data) {
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `政策管理数据${nowtime}.p` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
      } else {
        this.$message.error('下载失败!')
      }
    },
    // 文件下载
    downloadPolicy(item){
      if(item.fileUrl){
        this.imageDownload(item.fileUrl)
      } else {
        this.$message.warning('没有附件！')
      }
    },
    //解冻、冻结
    async setFreeze(row) {
      const { data: res } = await setFreeze({ id: row.id, isFreeze: !row.isFreeze })
      if (res.resultCode === 200) {
        this.$message.success('修改冻结状态成功！')
        this.search()
      } else {
        this.$message.error('修改冻结状态失败！')
      }
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 150px);
  overflow: auto;
}
.el-form-item ::v-deep .el-form-item__label {
  color: #7c7f8e;
}
.el-form-item ::v-deep .el-form-item__content {
  display: flex;
}
.distpicker-address-wrapper ::v-deep select {
  width: 150px;
}
.distpicker-address-wrapper ::v-deep label {
  margin-right: 10px;
}
.el-form-item__content ::v-deep .upload-demo {
  width: 100px;
}
.search_right {
  width: 560px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.el-form-item .row {
  display: flex;
  justify-content: space-between;
}
.el-form-item .radio_group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .wrap_left {
    width: 90%;
  }
  .el-radio-group {
    display: flex;
    flex-flow: row wrap;
  }
  ::v-deep .el-radio-button {
    .el-radio-button__inner {
      border-radius: 4px !important;
      border: 0 !important;
    }
  }
}
.showMore {
  cursor: pointer;
  display: flex;
  min-width: 80px;
  margin-right: 40px;
  overflow: hidden;
  span {
    color: #448aff;
    margin-right: 5px;
  }
}
.pagination {
  width: 100%;
  text-align: center;
  margin: 10px 0 20px 0;
}
.content_top {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 10px;
}
.color1 {
  color: #448aff;
}
.color2 {
  color: #fd5469;
}
.color3 {
  color: #999;
}
</style>
